import React, { createContext, useReducer } from 'react';
import { DateRange } from 'react-day-picker';
import { MultiSelectOption } from 'src/types/Forms/Forms';

export interface DashboardContextType {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

interface StateType {
  selectedBuildings: MultiSelectOption[];
  selectedUser: MultiSelectOption | undefined;
  dateRange: DateRange | undefined;
  filtersLocked: boolean;
}

type ActionType =
  | { type: 'SET_SELECTED_BUILDINGS'; payload: MultiSelectOption[] }
  | { type: 'SET_SELECTED_USER'; payload: MultiSelectOption | undefined }
  | { type: 'SET_DATE_RANGE'; payload: DateRange | undefined }
  | { type: 'SET_FILTERS_LOCKED'; payload: boolean };

const initialState: StateType = {
  selectedBuildings: [],
  selectedUser: undefined,
  dateRange: undefined,
  filtersLocked: false,
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_SELECTED_BUILDINGS':
      return { ...state, selectedBuildings: action.payload };
    case 'SET_SELECTED_USER':
      return { ...state, selectedUser: action.payload };
    case 'SET_DATE_RANGE':
      return { ...state, dateRange: action.payload };
    case 'SET_FILTERS_LOCKED':
      return { ...state, filtersLocked: action.payload };
    default:
      return state;
  }
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <DashboardContext.Provider value={{ state, dispatch }}>{children}</DashboardContext.Provider>;
};
