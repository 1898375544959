import React, { createContext, useReducer, useEffect } from 'react';
import { UserApi } from '../services/api/user';
import { useUser } from './UserContext';

interface TableauDashboardState {
  showTableau: boolean;
  currentDashboard: string;
  userSelected: string;
  token: string | null;
}

type TableauDashboardAction =
  | { type: 'setCurrentDashboard'; payload: string }
  | { type: 'setUserSelected'; payload: string }
  | { type: 'setShowTableau'; payload: boolean }
  | { type: 'setToken'; payload: string | null };

// Tableau Dashboard Context initial state
const initialState: TableauDashboardState = {
  showTableau: false,
  currentDashboard: 'executiveSummary', // one of jonathan's 6 standard dashboards
  userSelected: '', // user selected in the dropdown
  token: null,
};

export const TableauDashboardContext = createContext<{
  tableauDashboardState: TableauDashboardState;
  tableauDashboardDispatch: React.Dispatch<TableauDashboardAction>;
}>({
  tableauDashboardState: initialState,
  tableauDashboardDispatch: () => null,
});

export const TableauDashboardContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user: currentUser } = useUser();
  useEffect(() => {
    tableauDashboardDispatch({
      type: 'setShowTableau',
      payload: false,
    });
  }, [currentUser]);

  const getTableauJWT = async () => {
    try {
      const res = await UserApi.getTableauJWT();
      tableauDashboardDispatch({
        type: 'setToken',
        payload: res.data,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    // only fetch users data if organisationId is set
    // if the organisationId is not set, it is guaranteed that no users will be returned
    // hence, there is no need to fetch users data
    if (currentUser?.organisationId) {
      getTableauJWT();
    }
  }, [currentUser]);

  const [tableauDashboardState, tableauDashboardDispatch] = useReducer(
    (dashboardState: TableauDashboardState, action: TableauDashboardAction) => {
      switch (action.type) {
        case 'setCurrentDashboard':
          return { ...dashboardState, currentDashboard: action.payload };
        case 'setUserSelected':
          return {
            ...dashboardState,
            userSelected: action.payload,
          };
        case 'setShowTableau':
          return {
            ...dashboardState,
            showTableau: action.payload,
          };
        case 'setToken':
          return {
            ...dashboardState,
            token: action.payload,
          };
        default:
          return dashboardState;
      }
    },
    initialState
  );

  return (
    <TableauDashboardContext.Provider
      value={{
        tableauDashboardState,
        tableauDashboardDispatch,
      }}>
      {children}
    </TableauDashboardContext.Provider>
  );
};
