import http from '../http';

/**
 * Property Api Service
 */
export class PropertyService {
  urlPrefix = 'api/listing';

  /**
   * For Scrapping
   */
  import(data) {
    return http.get(`${this.urlPrefix}/import`, data);
  }

  importPropertyReports(data) {
    return http.post(`${this.urlPrefix}/import-report`, data);
  }

  getFiles(downloadId) {
    return http.get(`${this.urlPrefix}/download?fid=${downloadId}`);
  }

  createProperty(data) {
    return http.post(`${this.urlPrefix}`, data);
  }

  getPropertyCountByCity(data) {
    return http.get(`${this.urlPrefix}/property-count-by-city`, { params: data });
  }

  getPropertyById(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}`);
  }

  previewPropertyById(propertyId) {
    return http.get(`${this.urlPrefix}/properties/unique/${propertyId}`);
  }

  getRoomByPropertyId(propertyId) {
    return http.post(`${this.urlPrefix}/get-rooms`, propertyId);
  }

  updateProperty(propertyId, data) {
    return http.put(`${this.urlPrefix}/properties/${propertyId}`, data);
  }

  updatePrice(propertyId, data) {
    return http.post(`${this.urlPrefix}/properties/${propertyId}/update-price`, data);
  }

  updatePriceInBatch(data) {
    return http.post(`${this.urlPrefix}/properties/update-batch-price`, data);
  }

  addRoom(data) {
    return http.post(`${this.urlPrefix}/add-room`, data);
  }

  updateRoom(data) {
    return http.put(`${this.urlPrefix}/update-room`, data);
  }

  archiveProperty(propertyId) {
    return http.put(`${this.urlPrefix}/properties/${propertyId}/archive`);
  }

  getProperties() {
    return http.get(`${this.urlPrefix}/properties/`);
  }

  getAvailablePropertiesCount(data) {
    return http.post(`${this.urlPrefix}/available-properties-count`, data);
  }

  createExpense(data) {
    return http.post(`${this.urlPrefix}/expenses/`, data);
  }

  getExpensesByLandlord() {
    return http.get(`${this.urlPrefix}/expenses/`);
  }

  getInvoice(params) {
    return http.get(`${this.urlPrefix}/expenses/invoice`, { params });
  }

  // strictly loading amenities for individual properties
  getAmenitiesById(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/amenities`);
  }

  getUnitTurnDataById(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/unitTurnData`);
  }

  getYardiStatusDataById(propertyId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/yardiPropStatus`);
  }

  getTenantById(tenantId) {
    return http.get(`${this.urlPrefix}/tenant/${tenantId}`);
  }

  extendStay(data) {
    return http.post(`${this.urlPrefix}/extend-stay`, data);
  }

  importTenants(data) {
    return http.post(`${this.urlPrefix}/import-tenant`, data);
  }

  createTenantUser(data) {
    return http.post(`${this.urlPrefix}/create-tenant`, data);
  }

  addTenant(data) {
    return http.post(`${this.urlPrefix}/add-tenant`, data);
  }

  updateTenant(data) {
    return http.post(`${this.urlPrefix}/update-tenant`, data);
  }

  confirmTenantMoveOut(tenantId, data) {
    return http.put(`${this.urlPrefix}/confirm-tenant-moveout?tenantId=${tenantId}`, data);
  }

  inviteTenant(data) {
    return http.post(`${this.urlPrefix}/invite-tenant`, data);
  }

  sendRentSummary(data) {
    return http.post(`${this.urlPrefix}/rent-estimate`, data);
  }

  sendNotification(data) {
    return http.post(`${this.urlPrefix}/send-notification`, data);
  }

  getDataFromCondosApi(address) {
    return http.post(`${this.urlPrefix}/import-from-condos-api`, address);
  }

  getShowingsCount(roomId) {
    return http.post(`${this.urlPrefix}/showings-count`, { roomId });
  }

  getDocuments(propertyId, tenantId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/documents${tenantId ? `?tenantId=${tenantId}` : ''}`);
  }

  deleteDocument(propertyId, documentId) {
    return http.delete(`${this.urlPrefix}/properties/${propertyId}/documents/${documentId}`);
  }

  getDocumentUrl(propertyId, documentId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/documents/${documentId}/url`);
  }

  getBookingDocuments(bookingId) {
    return http.get(`${this.urlPrefix}/bookings/${bookingId}/documents`);
  }

  readInstructions(instructionId) {
    return http.post(`${this.urlPrefix}/instructions-read`, instructionId);
  }

  getInstructions(propertyId, roomId) {
    return http.get(`${this.urlPrefix}/properties/${propertyId}/instructions${roomId ? `?roomId=${roomId}` : ''}`);
  }

  getTenantInstructions(params) {
    return http.get(`${this.urlPrefix}/instructions-tenants`, { params });
  }

  sendInstruction(propertyId, data) {
    return http.post(`${this.urlPrefix}/properties/${propertyId}/instructions`, data);
  }

  createPayment(data) {
    return http.post(`${this.urlPrefix}/payments`, data);
  }

  updatePayment(paymentId, data) {
    return http.post(`${this.urlPrefix}/payments/${paymentId}`, data);
  }

  importProperties(data) {
    return http.post(`${this.urlPrefix}/import-property`, data);
  }

  getAvailablePropertiesByBuilding(data) {
    return http.post(`${this.urlPrefix}/available-properties-by-building`, data);
  }

  getTACData() {
    return http.get(`${this.urlPrefix}/get-tac-data`);
  }

  importTACData(data) {
    return http.post(`${this.urlPrefix}/import-tac-data`, data);
  }

  deleteTAC(data) {
    return http.post(`${this.urlPrefix}/delete-tac-data`, data);
  }

  editTAC(data) {
    return http.post(`${this.urlPrefix}/edit-tac-data`, data);
  }

  requestCreditCheck(data) {
    return http.post(`${this.urlPrefix}/request-credit-check`, data);
  }

  getCreditCheck(bookingId) {
    return http.get(`${this.urlPrefix}/get-credit-check-results/${bookingId}`);
  }

  downloadCreditReport(creditCheckId) {
    return http.get(`${this.urlPrefix}/download-credit-report/${creditCheckId}`);
  }

  getPropStatusData() {
    return http.get(`${this.urlPrefix}/properties/yardiPropStatus/get`);
  }
}

export const PropertyApi = new PropertyService();
