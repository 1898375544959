import React, { createContext, useReducer } from 'react';
import { Insight } from 'src/types/Insights/insight';
import { insightData, MonitoringInsight, monitoringInsightData } from 'data/insightData';

export interface InsightContextType {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

interface StateType {
  insightsDrawerOpen: boolean;
  activeInsight: Insight | undefined;
  allInsights: Insight[];
  activeTab: 'insight' | 'history';
  allMonitoringInsights: MonitoringInsight[];
}

type ActionType =
  | { type: 'SET_INSIGHTS_DRAWER_OPEN'; payload: boolean }
  | { type: 'SET_ACTIVE_INSIGHT'; payload: Insight | undefined }
  | { type: 'SET_ALL_INSIGHTS'; payload: Insight[] }
  | { type: 'SET_ACTIVE_TAB'; payload: 'insight' | 'history' }
  | { type: 'SET_ALL_MONITORING_INSIGHTS'; payload: MonitoringInsight[] };

const initialState: StateType = {
  insightsDrawerOpen: false,
  activeInsight: undefined,
  allInsights: [...insightData],
  activeTab: 'insight',
  allMonitoringInsights: [...monitoringInsightData],
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_INSIGHTS_DRAWER_OPEN':
      return { ...state, insightsDrawerOpen: action.payload };
    case 'SET_ACTIVE_INSIGHT':
      return { ...state, activeInsight: action.payload };
    case 'SET_ALL_INSIGHTS':
      return { ...state, allInsights: action.payload };
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload };
    case 'SET_ALL_MONITORING_INSIGHTS':
      return { ...state, allMonitoringInsights: action.payload };
    default:
      return state;
  }
};

export const InsightContext = createContext<InsightContextType | null>(null);

export const InsightContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <InsightContext.Provider value={{ state, dispatch }}>{children}</InsightContext.Provider>;
};
